import React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { useIntl } from 'gatsby-plugin-intl';

const AboutPage = ({ location, data: { site, contentfulSitePage } }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO pathname={location.pathname} title={intl.formatMessage({ id: 'menu-about' })} />

      <div className="relative w-11/12 max-w-screen-md mx-auto pt-24">
        <h1 className="font-bold text-2xl text-center mb-24">
          {contentfulSitePage.title} {site.siteMetadata.title}
        </h1>

        <div
          dangerouslySetInnerHTML={{ __html: contentfulSitePage.content?.childMarkdownRemark?.html }}
          className="rich-text"
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutPageQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulSitePage(slug: { eq: $slug }) {
      slug
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default AboutPage;
